import { useState } from 'react'
import {
    Table,
    Button
} from 'antd'
import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'

import Title from '../components/Title'

import { FIND_MANY_SCHEDULE, FIND_MANY_SCHEDULE_COUNT } from '../gqls'

const limit = 20

const Schedules = () => {
    const [currentPage, setCurrentPage] = useState(1)

    const { id } = useParams()

    const { data: findManyScheduleData, loading: findManyScheduleLoading } = useQuery(FIND_MANY_SCHEDULE, {
        variables: {
            take: limit,
            skip: limit * (currentPage - 1),
            orderBy: {
                weightSort: 'asc'
            },
            where: {
                objectId: {
                    equals: id
                },
                status: {
                    equals: true
                },
            }
        },
        fetchPolicy: 'network-only'
    })

    const { data: findManyScheduleCountData, loading: findManyScheduleCountLoading } = useQuery(FIND_MANY_SCHEDULE_COUNT, {
        variables: {
            where: {
                objectId: {
                    equals: id
                },
                status: {
                    equals: true
                },
            }
        },
        fetchPolicy: 'network-only',
    })

    const findManySchedule = findManyScheduleData?.findManySchedule || []
    const findManyScheduleCount = findManyScheduleCountData?.findManyScheduleCount || 0

    const handleChangeTable = ({ current }) => setCurrentPage(current)

    return (
        <>
            <Title
                text={`Список графиков (${findManyScheduleCount})`}
                action={
                    <Link to={'add'}>
                        <Button type='primary'>Добавить</Button>
                    </Link>
                }
            />
            <Link to={'archive'}>
                <Button variant="solid" color="cyan">Архив</Button>
            </Link>
            <Table
                loading={findManyScheduleLoading || findManyScheduleCountLoading}
                rowKey={(obj) => obj.id}
                dataSource={findManySchedule}
                scroll={{ x: 600 }}
                size={window.innerWidth < 500 ? 'small' : 'large'}
                pagination={{
                    current: currentPage,
                    total: findManyScheduleCount,
                    pageSize: limit
                }}
                onChange={handleChangeTable}
                columns={[
                    {
                        title: 'id',
                        dataIndex: 'id',
                        key: 'id',
                        render: (id) => <Link to={id}>{id}</Link>
                    },
                    {
                        title: 'Видимость на странице',
                        dataIndex: 'visible',
                        key: 'visible',
                        render: (visible) => <span>{visible ? 'Видно' : 'Скрыто'}</span>
                    },
                    {
                        title: 'Статус',
                        dataIndex: 'status',
                        key: 'status',
                        render: (status) => <span>{status ? 'Активный' : 'Архивирован'}</span>
                    },
                    {
                        title: 'Название',
                        dataIndex: 'name',
                        key: 'name',
                        render: (name) => <span>{name}</span>
                    },
                    {
                        title: 'Цена',
                        dataIndex: 'price',
                        key: 'price',
                        render: (price) => <span>{price} ₽</span>
                    },
                    {
                        title: 'Цена брони',
                        dataIndex: 'reserve',
                        key: 'reserve',
                        render: (reserve) => <span>{reserve} ₽</span>
                    },
                    {
                        title: 'График',
                        dataIndex: 'week',
                        key: 'week',
                        render: (week, object) => <span>{[...week, ...object.days].join(', ')}</span>
                    },
                    {
                        title: 'Закрытые даты',
                        dataIndex: 'closes',
                        key: 'closes',
                        render: (closes, object) => <span>{[...object.closes].join(', ')}</span>
                    },
                    {
                        title: 'Время',
                        dataIndex: 'startAt',
                        key: 'startAt',
                        render: (startAt, { endAt }) => <span>{startAt} - {endAt}</span>
                    },
                    {
                        title: 'Полный день',
                        dataIndex: 'fullday',
                        key: 'fullday',
                        render: (fullday) => <span>{fullday ? 'Да' : 'Нет'}</span>
                    },
                    {
                        title: 'Порядковый номер',
                        dataIndex: 'weightSort',
                        key: 'weightSort',
                        render: (weightSort) => <span>{weightSort}</span>
                    },
                ]}

            />
        </>
    )
}

export default Schedules