import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'

import Drawer from './Drawer'

import Auth from '../pages/Auth'

import Companies from '../pages/Companies'
import AddCompany from '../pages/AddCompany'
import Company from '../pages/Company'

import Objects from '../pages/Objects'
import AddObject from '../pages/AddObject'
import Object from '../pages/Object'

import Tours from '../pages/Tours'
import AddTour from '../pages/AddTour'
import Tour from '../pages/Tour'

import Posts from '../pages/Posts'
import AddPost from '../pages/AddPost'
import Post from '../pages/Post'

import Schedules from '../pages/Schedules'
import AddSchedule from '../pages/AddSchedule'
import Schedule from '../pages/Schedule'
import SchedulesArchive from '../pages/SchedulesArchive'

import Promotions from '../pages/Promotions'
import AddPromotion from '../pages/AddPromotion'
import Promotion from '../pages/Promotion'

import Icons from '../pages/Icons'
import AddIcon from '../pages/AddIcon'
import Icon from '../pages/Icon'

import Requests from '../pages/Requests'
import Request from '../pages/Request'

import Reviews from '../pages/Reviews'
import Review from '../pages/Review'

import RegistrationPayment from '../pages/RegistrationPayment'

import { useAdmin } from '../hooks/admin'
import CompanyAdminsTgBot from '../pages/CompanyAdminsTgBot'
import AddCompanyAdminTgBot from '../pages/AddCompanyAdminTgBot'
import CompanyAdminTgBot from '../pages/CompanyAdminTgBot'
import CompanyStatistics from '../pages/CompanyStatistics'
import WeeklyStatistics from '../pages/WeeklyStatistics'

import PushAdminTgBot from '../pages/PushAdminTgBot'
import SuperAdminTgBot from '../pages/SuperAdminTgBot'

const router = createBrowserRouter([
    {
        path: '/',
        element: <Drawer />,
        children: [
            {
                path: '/',
                element: <Navigate to='/companies' />
            },
            {
                path: 'companies',
                element: <Companies />
            },
            {
                path: 'companies/add',
                element: <AddCompany />
            },
            {
                path: 'companies/:id',
                element: <Company />
            },
            {
                path: 'companies/:id/registrationPayment',
                element: <RegistrationPayment />
            },
            {
                path: 'companies/:id/admins-tg-bot',
                element: <CompanyAdminsTgBot />
            },
            {
                path: 'companies/:id/admins-tg-bot/add',
                element: <AddCompanyAdminTgBot />
            },
            {
                path: 'companies/:id/admins-tg-bot/:id',
                element: <CompanyAdminTgBot />
            },
            {
                path: 'companies/:id/statistics',
                element: <CompanyStatistics />
            },
            {
                path: 'statistics/weekly',
                element: <WeeklyStatistics />
            },
            {
                path: 'objects',
                element: <Objects />
            },
            {
                path: 'objects/add',
                element: <AddObject />
            },
            {
                path: 'objects/:id',
                element: <Object />
            },
            {
                path: 'tours',
                element: <Tours />
            },
            {
                path: 'tours/add',
                element: <AddTour />
            },
            {
                path: 'tours/:id',
                element: <Tour />
            },
            {
                path: 'posts',
                element: <Posts />
            },
            {
                path: 'posts/add',
                element: <AddPost />
            },
            {
                path: 'posts/:id',
                element: <Post />
            },
            {
                path: 'objects/:id/schedules',
                element: <Schedules />
            },
            {
                path: 'objects/:id/schedules/add',
                element: <AddSchedule />
            },
            {
                path: 'objects/:id/schedules/:id',
                element: <Schedule />
            },
            {
                path: 'objects/:id/schedules/archive',
                element: <SchedulesArchive />
            },
            {
                path: 'objects/:id/schedules/archive/:id',
                element: <Schedule />
            },
            {
                path: 'promotions',
                element: <Promotions />
            },
            {
                path: 'promotions/add',
                element: <AddPromotion />
            },
            {
                path: 'promotions/:id',
                element: <Promotion />
            },
            {
                path: 'icons',
                element: <Icons />
            },
            {
                path: 'icons/add',
                element: <AddIcon />
            },
            {
                path: 'icons/:id',
                element: <Icon />
            },
            {
                path: 'requests',
                element: <Requests />
            },
            {
                path: 'requests/:id',
                element: <Request />
            },
            {
                path: 'reviews',
                element: <Reviews />
            },
            {
                path: 'reviews/:id',
                element: <Review />
            },
            {
                path: 'push-admin',
                element: <PushAdminTgBot />
            },
            {
                path: 'super-admin',
                element: <SuperAdminTgBot />
            },
        ],
    },
])

const auth = createBrowserRouter([
    {
        path: '/',
        element: <Auth />,
    }
])

const Router = () => {
    const { admin, loading: adminLoading } = useAdmin()

    if (adminLoading) return null

    if (admin) {
        return <RouterProvider router={router} />
    }

    return <RouterProvider router={auth} />
}

export default Router
