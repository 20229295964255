import styled from 'styled-components'
import { Layout as AntLayout, Button, Popconfirm, Menu } from 'antd'
import { Outlet, Link } from 'react-router-dom'
import { useState, useEffect } from 'react'

const { Content: AntContent, Sider: AntSider } = AntLayout

const Content = styled(AntContent)`
    margin: 20px 20px 0;

    @media only screen and (max-width: 480px) {
        margin: 10px 0;
    }

    .inside {
        padding: 18px 22px;
        min-height: 100%;

        @media only screen and (max-width: 480px) {
            padding: 20px 14px;
        }
    }
`

const Provider = styled(AntLayout)`
    min-height: 100vh;

    .ant-layout-sider-zero-width-trigger {
        top: 11px; !important;
        position: fixed;
        z-index: 10;
        right: auto;
        transition: right 0.2s ease; // Плавный переход для изменения положения
    }
`

const Sider = styled(AntSider)`
    z-index: 3;

    // Динамическая ширина в зависимости от размера экрана
    width: 300px; // Базовая ширина для больших экранов (> 1200px)

    @media only screen and (max-width: 1200px) {
        width: 250px; // Средние экраны (992px - 1200px)
    }

    @media only screen and (max-width: 992px) {
        width: 200px; // Меньше lg (768px - 992px)
        position: absolute;
        height: 100%;
    }

    @media only screen and (max-width: 768px) {
        width: 150px; // Мобильные устройства (480px - 768px)
    }

    @media only screen and (max-width: 480px) {
        width: 120px; // Очень маленькие экраны (< 480px)
    }
`

const MenuLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        color: white;
    }
`

export default function Drawer() {
    const [collapsed, setCollapsed] = useState(true)
    const [siderWidth, setSiderWidth] = useState(200)
    const logOut = async () => {
        await localStorage.clear()
        window.location.replace('/')
    }

    const handleCollapse = (collapsed) => {
        setCollapsed(collapsed) // Обновляем состояние при сворачивании/разворачивании
    }
    // Обновляем ширину Sider в зависимости от размера экрана
    useEffect(() => {
        const updateSiderWidth = () => {
            const width = window.innerWidth
            if (width <= 480) {
                setSiderWidth(150)
            } else if (width <= 768) {
                setSiderWidth(170)
            } else if (width <= 992) {
                setSiderWidth(200)
            } else if (width <= 1200) {
                setSiderWidth(250)
            } else {
                setSiderWidth(300)
            }
        }

        // Устанавливаем начальную ширину
        updateSiderWidth()

        // Добавляем слушатель изменения размера окна
        window.addEventListener('resize', updateSiderWidth)

        // Очищаем слушатель при размонтировании
        return () => window.removeEventListener('resize', updateSiderWidth)
    }, [])

    useEffect(() => {
        const trigger = document.querySelector('.ant-layout-sider-zero-width-trigger')
        if (trigger) {
            const width = window.innerWidth
            if (width <= 400) {
                trigger.style.right = collapsed ? 'auto' : `${siderWidth + 45}px`
            } else if (width <= 768) {
                trigger.style.right = collapsed ? 'auto' : `${siderWidth + 90}px`
            } else {
                trigger.style.right = collapsed ? 'auto' : `${siderWidth + 45}px`
            }
            trigger.style.background = '#001529';
            trigger.style.color = '#fff';
            trigger.style.padding = '10px';
        }
    }, [collapsed])

    return (
        <Provider>
            <Sider style={{ minHeight: '100vh' }} breakpoint='lg' collapsedWidth='0' onCollapse={handleCollapse} width={siderWidth} // Отслеживаем состояние collapsed
                >
                <Menu style={{ height: '50%', paddingBottom: 24 }} theme='dark' mode='inline' defaultSelectedKeys={['/companies']}>
                    <Menu.Item key={`/companies`}>
                        <MenuLink to={`/companies`}>
                            Компании
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/objects`}>
                        <MenuLink to={`/objects`}>
                            Обьекты
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/promotions`}>
                        <MenuLink to={`/promotions`}>
                            Подборки
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/tours`}>
                        <MenuLink to={`/tours`}>
                            Туры
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/posts`}>
                        <MenuLink to={`/posts`}>
                            Лента
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/requests`}>
                        <MenuLink to={`/requests`}>
                            Заявки
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/reviews`}>
                        <MenuLink to={`/reviews`}>
                            Отзывы
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/icons`}>
                        <MenuLink to={`/icons`}>
                            Иконки
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/push-admin`}>
                        <MenuLink to={`/push-admin`}>
                            Пуш тг админам
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/super-admin`}>
                        <MenuLink to={`/super-admin`}>
                            Супер админ тг бота
                        </MenuLink>
                    </Menu.Item>
                    <Menu.Item key={`/statistics/weekly`}>
                        <MenuLink to={`/statistics/weekly`}>
                            Статистика weekly
                        </MenuLink>
                    </Menu.Item>
                </Menu>
                <div style={{ display: 'flex', height: '50%', justifyContent: 'center', alignItems: 'flex-end' }}>
                    <Popconfirm title='Выйти?' onConfirm={logOut}>
                        <Button style={{ width: 'calc(100% - 12px)', marginBottom: 12 }} danger ghost type='primary'>Выйти с аккаунта</Button>
                    </Popconfirm>
                </div>
            </Sider>
            <AntLayout>
                <Content>
                    <div className='inside'>
                        <Outlet />
                    </div>
                </Content>
            </AntLayout>
        </Provider>
    );
}
